import Loader from "common/Loader"
import ReactMultiSelect from "constants/ReactMultiSelect"
import ReactSelect from "constants/ReactSelect"
import React, { useEffect, useState } from "react"
import { Button, Col, Input, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData, investmentData } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import getBase64 from "base64"

const SelectUsers = props => {
  const {
    loading,
    setLoading,
    marketingData,
    setMarketingData,
    excludedUsers,
    setExcludedUsers,
    emailCsvData,
    setEmailCsvData,
    userData,
    setUserData,
    accessRestriction,
    nextModalStep,
    paramsList,
    validParams,
    setValidParams,
  } = props
  // Filter States
  const [selectedUserGroupType, setSelectedUserGroupType] =
    useState("filter_users")
  const [selectedUserType, setSelectedUserType] = useState()
  const [selectedFilteringCriteria, setSelectedFilteringCriteria] = useState()

  const [allInvestors, setAllInvestors] = useState(true)
  const [selectedInvestorType, setSelectedInvestorType] = useState({
    id: "invested",
    displayText: "Invested",
  })

  const [selectedNonInvestorType, setSelectedNonInvestorType] = useState({
    id: "all_users",
    displayText: "All (Email Verified)",
  })

  const [selectedDistributorOption, setSelectedDistributorOption] = useState({
    id: "all_distributors",
    displayText: "All Distributors",
  })
  const [investments, setInvestments] = useState([])
  const [selectedProjects, setSelectedProjects] = useState([])
  const [file, setFile] = useState(null)
  const [base64, setBase64] = useState("")
  const [fileName, setFileName] = useState("")
  const [consent, setConsent] = useState(false)

  // Logic
  const MarketingEmailSummaryColumns = (download = false) => {
    const cols = [
      { dataField: "user_name", text: "User Name" },
      { dataField: "email", text: "Email" },
    ]
    if (validParams.length >= 4) {
      cols.push(
        { dataField: "rm_name", text: "Relationship Manager Name" },
        { dataField: "rm_email", text: "Relationship Manager Email" },
        { dataField: "rm_phone", text: "Relationship Manager Phone" }
      )
    }
    if (validParams.length == 7) {
      cols.push(
        { dataField: "distributor_name", text: "Distributor Name" },
        { dataField: "distributor_email", text: "Distributor Email" },
        { dataField: "distributor_phone", text: "Distributor Phone" }
      )
    }
    return cols
  }

  const reset = () => {
    setSelectedUserType(null)
    setSelectedFilteringCriteria(null)
    setMarketingData([])
    setExcludedUsers([])
    setEmailCsvData([])
    setSelectedProjects([])
    setFile()
    setFileName("")
    setBase64("")
    setUserData([])
    setConsent(false)
    setSelectedDistributorOption({
      id: "all_distributors",
      displayText: "All Distributors",
    })
    setAllInvestors(true)
  }

  useEffect(() => {
    reset()
    if (selectedUserGroupType === "distributors") {
      setValidParams(paramsList.slice(0, 4))
    } else if (selectedUserGroupType === "custom_user_list") {
      setValidParams(paramsList.slice(0, 1))
    } else {
      setValidParams(paramsList)
    }
  }, [selectedUserGroupType])

  useEffect(async () => {
    if (selectedUserGroupType === "distributors") {
      setMarketingData([])
      setExcludedUsers([])
      setUserData([])
      setEmailCsvData([])
      setSelectedProjects([])
      if (
        selectedDistributorOption?.id === "distributors_by_deal_participation"
      ) {
        try {
          setLoading(true)

          const investmentRes = await investmentData(
            "Approved",
            "Closed",
            "Exited"
          )
          const investmentList =
            investmentRes?.data?.data || investmentRes?.data
          setInvestments(investmentList)
        } catch (error) {
          toast.error(error.message)
        } finally {
          setLoading(false)
        }
      } else {
        if (selectedDistributorOption?.id === "all_distributors") {
          try {
            setLoading(true)
            const marketingRes = await axiosInstance.post(`marketing-data`, {
              filteringCriteria: selectedDistributorOption?.id,
            })
            const marketingData = marketingRes?.data
            if (marketingData?.data?.length) {
              setMarketingData(marketingData)
              setUserData(marketingData?.data)
              const emailCsvData = csvDownloadData(
                MarketingEmailSummaryColumns(),
                marketingData?.data
              )
              setEmailCsvData(emailCsvData)
            } else {
              setMarketingData([])
              setUserData([])
              setEmailCsvData([])
              toast.warn("No users found for selected filters!")
            }
          } catch (error) {
            console.log({ error })
          } finally {
            setLoading(false)
          }
        }
      }
    }
  }, [selectedDistributorOption])

  const getMarketingData = async (
    selectedUserType,
    selectedFilteringCriteria,
    selectedInvestorType,
    selectedProjects = [],
    selectedNonInvestorType
  ) => {
    setExcludedUsers([])
    try {
      setLoading(true)
      const marketingRes = await axiosInstance.post(`marketing-data`, {
        selectedUserType: selectedUserType?.id,
        filteringCriteria: selectedFilteringCriteria?.id,
        selectedInvestorType: allInvestors
          ? "all_investors"
          : selectedInvestorType?.id,
        selectedProjects: selectedProjects?.map(el => {
          return { id: el?.id, project_name: el?.project_name }
        }),
        selectedNonInvestorType: selectedNonInvestorType?.id,
      })
      const marketingData = marketingRes?.data
      if (marketingData?.data?.length) {
        setMarketingData(marketingData)
        setUserData(marketingData?.data)
        const emailCsvData = csvDownloadData(
          MarketingEmailSummaryColumns(),
          marketingData?.data
        )
        setEmailCsvData(emailCsvData)
      } else {
        setMarketingData([])
        setUserData([])
        setEmailCsvData([])
        toast.warn("No users found for selected filters!")
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    if (selectedUserType?.id && selectedFilteringCriteria?.id) {
      setMarketingData([])
      setExcludedUsers([])
      setUserData([])
      setEmailCsvData([])
      setValidParams(paramsList)
      if (selectedUserType?.id === "direct_users") {
        setValidParams(paramsList.slice(0, 4))
      }
      if (selectedFilteringCriteria?.id === "all_users") {
        setValidParams(paramsList.slice(0, 1))
      }
      if (selectedFilteringCriteria?.id == "non_investors") {
        if (selectedNonInvestorType?.id === "all_users") {
          setValidParams(paramsList.slice(0, 1))
        }
      }
      if (selectedFilteringCriteria?.id != "current_investors") {
        setAllInvestors(true)
        setSelectedProjects([])
        setSelectedInvestorType({
          id: "invested",
          displayText: "Invested",
        })
      }

      if (selectedFilteringCriteria?.id != "non_investors") {
        setSelectedNonInvestorType({
          id: "all_users",
          displayText: "All (Email Verified)",
        })
      }
      switch (selectedFilteringCriteria?.id) {
        case "current_investors":
          if (!selectedProjects?.length && !allInvestors) {
            break
          }
        default:
          getMarketingData(
            selectedUserType,
            selectedFilteringCriteria,
            selectedInvestorType,
            selectedProjects,
            selectedNonInvestorType
          )
          break
      }
    } else {
      setMarketingData([])
      setUserData([])
      setEmailCsvData([])
    }
  }, [selectedUserType, selectedFilteringCriteria])

  useEffect(async () => {
    if (selectedProjects?.length) {
      if (selectedUserGroupType === "filter_users") {
        getMarketingData(
          selectedUserType,
          selectedFilteringCriteria,
          selectedInvestorType,
          selectedProjects,
          selectedNonInvestorType
        )
      } else {
        try {
          setLoading(true)
          const marketingRes = await axiosInstance.post(`marketing-data`, {
            filteringCriteria: selectedDistributorOption?.id,
            selectedProjects: selectedProjects?.map(el => {
              return { id: el?.id, project_name: el?.project_name }
            }),
          })
          const marketingData = marketingRes?.data
          if (marketingData?.data?.length) {
            setMarketingData(marketingData)
            setUserData(marketingData?.data)
            const emailCsvData = csvDownloadData(
              MarketingEmailSummaryColumns(),
              marketingData?.data
            )
            setEmailCsvData(emailCsvData)
          } else {
            setMarketingData([])
            setUserData([])
            setEmailCsvData([])
            toast.warn("No users found for selected filters!")
          }
        } catch (error) {
          console.log({ error })
        } finally {
          setLoading(false)
        }
      }
    } else {
      setMarketingData([])
      setUserData([])
      setEmailCsvData([])
    }
  }, [selectedProjects, selectedInvestorType])

  useEffect(async () => {
    if (selectedUserType?.id && selectedFilteringCriteria?.id && allInvestors) {
      getMarketingData(
        selectedUserType,
        selectedFilteringCriteria,
        selectedInvestorType,
        selectedProjects,
        selectedNonInvestorType
      )
    } else {
      setMarketingData([])
      setUserData([])
      setEmailCsvData([])
      setSelectedProjects([])
      setSelectedInvestorType({
        id: "invested",
        displayText: "Invested",
      })
      try {
        setLoading(true)
        if (!allInvestors) {
          const investmentRes = await investmentData(
            "Approved",
            "Closed",
            "Exited"
          )
          const investmentList =
            investmentRes?.data?.data || investmentRes?.data
          setInvestments(investmentList)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [allInvestors])

  useEffect(() => {
    if (selectedFilteringCriteria?.id === "non_investors") {
      if (selectedNonInvestorType?.id === "all_users") {
        setValidParams(paramsList.slice(0, 1))
      } else if (selectedUserType?.id === "direct_users") {
        setValidParams(paramsList.slice(0, 4))
      } else {
        setValidParams(paramsList)
      }
      getMarketingData(
        selectedUserType,
        selectedFilteringCriteria,
        selectedInvestorType,
        selectedProjects,
        selectedNonInvestorType
      )
    }
  }, [selectedNonInvestorType])

  useEffect(() => {
    if (excludedUsers?.length) {
      const excludedUserIds = excludedUsers.map(item => item.id)
      const userData = marketingData?.data?.filter(
        el => !excludedUserIds.includes(el.id)
      )
      setUserData(userData)
      const emailCsvData = csvDownloadData(
        MarketingEmailSummaryColumns(),
        userData
      )
      setEmailCsvData(emailCsvData)
    } else if (marketingData?.data?.length) {
      setUserData([...marketingData?.data])
      const emailCsvData = csvDownloadData(
        MarketingEmailSummaryColumns(),
        marketingData?.data
      )
      setEmailCsvData(emailCsvData)
    }
  }, [excludedUsers])

  const onSelectFile = e => {
    setFile(e.target.files[0])
    setFileName(e.target.files[0].name)
  }
  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const userTypes = [
    { id: "direct_users", displayText: "Direct Users" },
    { id: "through_distributors", displayText: "Through Distributors" },
    { id: "both", displayText: "Both" },
  ]

  const filteringCriterias = [
    { id: "all_users", displayText: "All Users (Email Verified)" },
    { id: "all_registered_users", displayText: "Registered Users" },
    { id: "kyc_verified_users", displayText: "KYC Verified Users" },
    { id: "current_investors", displayText: "Current Investors" },
    { id: "non_investors", displayText: "Non Investors" },
  ]

  const distributorOptions = [
    {
      id: "all_distributors",
      displayText: "All Distributors",
    },
    {
      id: "distributors_by_deal_participation",
      displayText: "Distributors by Deal Participation",
    },
  ]

  const currentInvestorOptions = [
    {
      id: "invested",
      displayText: "Invested",
    },
    {
      id: "not_invested",
      displayText: "Not Invested",
    },
  ]
  const currentNonInvestorOptions = [
    { id: "all_users", displayText: "All (Email Verified)" },
    {
      id: "registered_users",
      displayText: "Registered",
    },
    {
      id: "kyc_verified",
      displayText: "KYC Verified",
    },
  ]

  const sampleData = [
    {
      user_name: "Sample User",
      email: "sample@earnnest.me",
    },
  ]

  const handleSubmit = async (e, values) => {
    if (selectedUserGroupType === "custom_user_list") {
      const dataToUpload = {
        selectedUserType: "both",
        filteringCriteria: selectedUserGroupType,
        users: base64.replace("data:text/csv;base64,", ""),
      }

      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/marketing-data",
            dataToUpload
          )
          if (uploadResponse && uploadResponse?.data?.data) {
            setUserData(uploadResponse.data.data)
          }
          nextModalStep()
        } catch (error) {
          console.log({ error })
          return
        }
      } else {
        toast.error("Please select a valid file")
        return
      }
    }
    nextModalStep()
    return
  }

  return (
    <div>
      <h4 className="mb-1">Select Users</h4>
      <hr className="bg-secondary border-2 border-top border-secondary mt-0" />
      <Row form className="mb-3 row">
        <Col md={9}>
          <div className="mb-3">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link${
                    selectedUserGroupType === "filter_users" ? " active" : ""
                  }`}
                  id="filter_users"
                  type="button"
                  onClick={() => setSelectedUserGroupType("filter_users")}
                >
                  Filter Users
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link${
                    selectedUserGroupType === "distributors" ? " active" : ""
                  }`}
                  id="distributors"
                  type="button"
                  onClick={() => setSelectedUserGroupType("distributors")}
                >
                  Distributors
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link${
                    selectedUserGroupType === "custom_user_list"
                      ? " active"
                      : ""
                  }`}
                  id="custom_user_list"
                  type="button"
                  onClick={() => setSelectedUserGroupType("custom_user_list")}
                >
                  Custom User List
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent"></div>
          </div>
          {selectedUserGroupType === "filter_users" ? (
            <>
              <div className="mb-3">
                <label>Select User Type</label>
                <ReactSelect
                  users={userTypes}
                  selectedOption={selectedUserType}
                  setSelectedOption={setSelectedUserType}
                  multiOptionLabel={true}
                  optionLabelKeys={["displayText"]}
                />
              </div>
              <div className="mb-3">
                <label>Select Filtering Criteria</label>
                <ReactSelect
                  users={filteringCriterias}
                  selectedOption={selectedFilteringCriteria}
                  setSelectedOption={setSelectedFilteringCriteria}
                  multiOptionLabel={true}
                  optionLabelKeys={["displayText"]}
                />
              </div>
              {selectedFilteringCriteria?.id === "current_investors" ? (
                <>
                  <div className="d-flex gap-2 mb-3">
                    <input
                      type="checkbox"
                      id="allInvestors"
                      checked={allInvestors}
                      onChange={e => setAllInvestors(e.target.checked)}
                    />{" "}
                    <label className="mb-0 text-muted" htmlFor="allInvestors">
                      All investors selected. To filter by deal, uncheck this.
                    </label>
                  </div>
                  {!allInvestors ? (
                    <div className="row align-items-center">
                      <div className="col-4 mb-3">
                        <ReactSelect
                          users={currentInvestorOptions}
                          setSelectedOption={setSelectedInvestorType}
                          multiOptionLabel={true}
                          optionLabelKeys={["displayText"]}
                          selectedOption={selectedInvestorType}
                        />
                      </div>
                      <div className="col-1 mb-3 text-center">in</div>
                      <div className="col-7 mb-3">
                        <ReactMultiSelect
                          options={investments}
                          multiOptionLabel={true}
                          optionLabelKeys={["project_name", "location"]}
                          selectedOption={selectedProjects}
                          setSelectedOption={setSelectedProjects}
                        />
                      </div>{" "}
                    </div>
                  ) : null}
                </>
              ) : null}
              {selectedFilteringCriteria?.id === "non_investors" ? (
                <div className="mb-3">
                  <Label>Select Non-Investors</Label>
                  <ReactSelect
                    users={currentNonInvestorOptions}
                    setSelectedOption={setSelectedNonInvestorType}
                    multiOptionLabel={true}
                    optionLabelKeys={["displayText"]}
                    selectedOption={selectedNonInvestorType}
                  />
                </div>
              ) : null}
              <div className="mb-3">
                <label>Exclude Users</label>
                <ReactMultiSelect
                  options={marketingData?.data}
                  multiOptionLabel={true}
                  optionLabelKeys={["user_name", "email", "phone"]}
                  isDisabled={!marketingData?.data?.length}
                  selectedOption={excludedUsers}
                  setSelectedOption={setExcludedUsers}
                />
              </div>
              {userData?.length ? (
                <p className="mb-3 text-muted">
                  Total Recipients: <b>{userData?.length}</b>{" "}
                  {accessRestriction === "SuperAdmin" ? (
                    <>
                      (
                      <a
                        onClick={() =>
                          JSONToCSVConvertor(
                            emailCsvData,
                            "Marketing Email Summary",
                            true
                          )
                        }
                        className="link"
                      >
                        Download Summary
                      </a>
                      )
                    </>
                  ) : null}
                </p>
              ) : null}
            </>
          ) : null}
          {selectedUserGroupType === "distributors" ? (
            <div>
              <div>
                <label>Select Distributors</label>
                <div className="mb-3">
                  <ReactSelect
                    users={distributorOptions}
                    setSelectedOption={setSelectedDistributorOption}
                    multiOptionLabel={true}
                    optionLabelKeys={["displayText"]}
                    selectedOption={selectedDistributorOption}
                  />
                </div>
              </div>
              {selectedDistributorOption?.id !== "all_distributors" ? (
                <div>
                  <label>Select Deals</label>
                  <div className="mb-3">
                    <ReactMultiSelect
                      options={investments}
                      multiOptionLabel={true}
                      optionLabelKeys={["project_name", "location"]}
                      selectedOption={selectedProjects}
                      setSelectedOption={setSelectedProjects}
                    />
                  </div>
                </div>
              ) : null}
              <div className="mb-3">
                <label>Exclude Distributors</label>
                <ReactMultiSelect
                  options={marketingData?.data}
                  multiOptionLabel={true}
                  optionLabelKeys={["user_name", "email", "phone"]}
                  isDisabled={!marketingData?.data?.length}
                  selectedOption={excludedUsers}
                  setSelectedOption={setExcludedUsers}
                />
              </div>
              {userData?.length ? (
                <p className="mb-3 text-muted">
                  Total Recipients: <b>{userData?.length}</b>{" "}
                  {accessRestriction === "SuperAdmin" ? (
                    <>
                      (
                      <a
                        onClick={() =>
                          JSONToCSVConvertor(
                            emailCsvData,
                            "Marketing Email Summary",
                            true
                          )
                        }
                        className="link"
                      >
                        Download Summary
                      </a>
                      )
                    </>
                  ) : null}
                </p>
              ) : null}
            </div>
          ) : null}
          {selectedUserGroupType === "custom_user_list" ? (
            <div className="mt-2 mb-3">
              {loading && <Loader />}
              <div className="row justify-content-between">
                <Label htmlFor="formFile" className="form-label col">
                  Upload User List
                </Label>
                <p
                  onClick={() =>
                    JSONToCSVConvertor(sampleData, "Sample User List", true)
                  }
                  className="col text-end"
                >
                  <a className="link">Sample CSV</a>
                </p>
              </div>
              <Input
                className="form-control"
                type="file"
                accept=".csv"
                id="formFile"
                validate={{
                  required: { value: true },
                }}
                onChange={onSelectFile}
              />
              <p className="mt-3">
                {fileName ? `File uploaded: ${fileName.split("/").pop()}` : ""}
              </p>
            </div>
          ) : null}
        </Col>
        {userData?.length ? (
          <Col md={3} className="font-size-14">
            <b>Dynamic Params</b>
            <br />
            <br />
            <ul>
              {validParams?.map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ul>
          </Col>
        ) : null}
      </Row>
      <Row className="col-9 mb-3 justify-content-between">
        <div className="col-auto d-flex gap-2 justify-content-end align-items-center">
          <input
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={e => setConsent(e.target.checked)}
          />{" "}
          <label className="mb-0 text-muted" htmlFor="consent">
            I confirm that I have reviewed the recipient list
          </label>
        </div>
        <div className="col-auto text-end pe-0">
          <Button
            type="submit"
            color="success"
            className={`btn-md save-user`}
            disabled={
              (selectedUserGroupType === "custom_user_list"
                ? !base64
                : !userData?.length) || !consent
            }
            onClick={handleSubmit}
          >
            Next Step
          </Button>
        </div>
      </Row>
    </div>
  )
}

SelectUsers.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  marketingData: PropTypes.object,
  setMarketingData: PropTypes.func,
  excludedUsers: PropTypes.array,
  setExcludedUsers: PropTypes.func,
  emailCsvData: PropTypes.array,
  setEmailCsvData: PropTypes.func,
  userData: PropTypes.array,
  setUserData: PropTypes.func,
  accessRestriction: PropTypes.any,
  nextModalStep: PropTypes.func,
  paramsList: PropTypes.array,
  validParams: PropTypes.array,
  setValidParams: PropTypes.func,
}

export default SelectUsers
